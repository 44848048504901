h1,
ul,
li,
span,
p,
a,
label {
  color: #00184c;
}

html,
body,
#root {
  height: 100%;
}

.semi-bold {
  font-weight: 500;
}
